export default {
  size: {
    borderRadius: "0.375em",
    elementHeight: "2.75em",
    elementMargin: "1em",
    sidebarWidth: "26em",
    sidebarWidthAlt: "24em",
    gutter: "3em",
  },
  palette: {
    bg: "#ffffff",
    bgAlt: "#f5f6f7",
    fg: "#677077",
    fgBold: "#3d4449",
    fgLight: "#656e75",
    border: "rgba(210,215,217,0.75)",
    accent: "#b04949",
    borderBg: "transparentize(#e6ebed, 0.75)",
  },
  font: {
    family: "Helvetica, sans-serif",
    familyHeading: "Georgia, serif",
    familyFixed: '"Courier New", monospace',
    weight: 400,
    weightBold: 600,
    weightHeading: 700,
    weightHeadingAlt: 400,
    kerningHeading: "0.075em",
  },
};
